<script setup lang="ts">
import { SpeedInsights } from '@vercel/speed-insights/nuxt';
import { useUserStore } from '~/store/user';
import AppBar from '~/components/layout/AppBar.vue';
import SideNav from '~/components/layout/SideNav.vue';

const userStore = useUserStore();
userStore.fetchUser();
</script>

<template>
  <v-card variant="flat">
    <SpeedInsights :sample-rate="0.5" />
    <v-layout>
      <SideNav :user-name="userStore?.name" />
      <AppBar />
      <v-main>
        <NuxtPage />
      </v-main>
    </v-layout>
  </v-card>
</template>
